////////////////////////////////////////////////////////////////////////////////////////////////
// GALLERY PREVIEWS ////////////////////////////////////////////////////////////////////////////

// тренажерный зал
import gallery_preview_slide_01 from './images/gallery/previews/slide_01.jpg'
import gallery_preview_slide_02 from './images/gallery/previews/slide_02.jpg'
import gallery_preview_slide_03 from './images/gallery/previews/slide_03.jpg'
import gallery_preview_slide_04 from './images/gallery/previews/slide_04.jpg'
import gallery_preview_slide_05 from './images/gallery/previews/slide_05.jpg'
import gallery_preview_slide_06 from './images/gallery/previews/slide_06.jpg'
import gallery_preview_slide_07 from './images/gallery/previews/slide_07.jpg'
import gallery_preview_slide_08 from './images/gallery/previews/slide_08.jpg'
import gallery_preview_slide_09 from './images/gallery/previews/slide_09.jpg'
import gallery_preview_slide_10 from './images/gallery/previews/slide_10.jpg'

// групповые занятия
import gallery_preview_slide_11 from './images/gallery/previews/slide_11.jpg'
import gallery_preview_slide_12 from './images/gallery/previews/slide_12.jpg'
import gallery_preview_slide_13 from './images/gallery/previews/slide_13.jpg'

// спа
import gallery_preview_slide_14 from './images/gallery/previews/slide_14.jpg'
import gallery_preview_slide_15 from './images/gallery/previews/slide_15.jpg'
import gallery_preview_slide_16 from './images/gallery/previews/slide_16.jpg'
import gallery_preview_slide_17 from './images/gallery/previews/slide_17.jpg'


////////////////////////////////////////////////////////////////////////////////////////////////
// GALLERY SLIDES //////////////////////////////////////////////////////////////////////////////

// тренажёрный зал
import gallery_slide_01 from './images/gallery/slides/slide_01.jpg'
import gallery_slide_02 from './images/gallery/slides/slide_02.jpg'
import gallery_slide_03 from './images/gallery/slides/slide_03.jpg'
import gallery_slide_04 from './images/gallery/slides/slide_04.jpg'
import gallery_slide_05 from './images/gallery/slides/slide_05.jpg'
import gallery_slide_06 from './images/gallery/slides/slide_06.jpg'
import gallery_slide_07 from './images/gallery/slides/slide_07.jpg'
import gallery_slide_08 from './images/gallery/slides/slide_08.jpg'
import gallery_slide_09 from './images/gallery/slides/slide_09.jpg'
import gallery_slide_10 from './images/gallery/slides/slide_10.jpg'

// групповые занятия
import gallery_slide_11 from './images/gallery/slides/slide_11.jpg'
import gallery_slide_12 from './images/gallery/slides/slide_12.jpg'
import gallery_slide_13 from './images/gallery/slides/slide_13.jpg'

// спа
import gallery_slide_14 from './images/gallery/slides/slide_14.jpg'
import gallery_slide_15 from './images/gallery/slides/slide_15.jpg'
import gallery_slide_16 from './images/gallery/slides/slide_16.jpg'
import gallery_slide_17 from './images/gallery/slides/slide_17.jpg'


////////////////////////////////////////////////////////////////////////////////////////////////
// EXPORTS /////////////////////////////////////////////////////////////////////////////////////

export const club = {
    name: 'DDX Мытищи Июнь',
    tour_3d: 'https://my.matterport.com/show/?m=jiZWvAGp4Vu',
    rating: '5,0',
    rating_number: 5.0,
    reviews: '578 отзывов',
    reviews_link: 'https://yandex.ru/maps/org/ddx_fitness/116783427292/reviews/?ll=37.708482%2C55.919962&z=16',
    address: 'г. Мытищи, ул. Мира, д. 51, ТЦ "Июнь", 2 этаж',
    route: 'https://yandex.ru/maps/10740/mytischi/?ll=37.713842%2C55.928167&mode=routes&rtext=~55.918867%2C37.707598&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D116783427292&z=14',
    phone: '+7 (495) 162-56-55',
    phone_link: 'tel:74951625655',
    schedule: 'Ежедневно с 6 до 24',
    coordinates: [55.918867, 37.707599],
    clubs_total: 38,
    ym_code: 95203880,
    vk_code: 3401225,
    clubs_link_with_utms: 'https://ddxfitness.ru/clubs?utm_source=landing_mytishchi&utm_medium=cpc&utm_campaign=performance',
}

export const HERO_SLIDES = [
    // '/images/hero/slide_01.webp',
    '/images/hero/slide_02.jpg',
    '/images/hero/slide_03.jpg',
    '/images/hero/slide_04.jpg',
    '/images/hero/slide_05.jpg',
]

export const ZONES_SLIDES = [
    { 
        name: 'Тренажёрный зал', 
        formattedName: 'Тренажёрный зал', 
        title: <div>ТРЕНАЖЁРНЫЙ <br />ЗАЛ</div>, 
        image: '/images/hero/slide_04.jpg',
        preview: '/images/hero/slide_04.jpg',
        list: ['1000 кв. метров топового оборудования', '30 кардио тренажеров', '17 силовых тренажеров', 'Зона свободных весов']
    },
    {
        name: 'Зал групповых занятий',
        formattedName: <span>Зал&nbsp;групповых занятий</span>,
        title: <div>Зал&nbsp;групповых занятий</div>,
        image: '/images/zones/slide_02.jpg',
        preview: '/images/zones/slide_02.jpg',
        list: ['DDX Athletic', 'DDX Athletic', 'Cross Box']
    },
    { 
        name: 'СПА',
        formattedName: 'СПА',
        title: 'СПА',
        image: '/images/zones/slide_03.jpg',
        preview: '/images/zones/slide_03.jpg',
        list: ['60 кв. метра', 'Соляная комната', 'Хамам и сауна', '2 гидромассажных джакузи']
    },
]

export const PREVIEW_SLIDES = [
    { id: 'gallery_slide_01', image: gallery_preview_slide_01 },
    { id: 'gallery_slide_02', image: gallery_preview_slide_02 },
    { id: 'gallery_slide_03', image: gallery_preview_slide_03 },
    { id: 'gallery_slide_04', image: gallery_preview_slide_04 },
    { id: 'gallery_slide_05', image: gallery_preview_slide_05 },
    { id: 'gallery_slide_06', image: gallery_preview_slide_06 },
    { id: 'gallery_slide_07', image: gallery_preview_slide_07 },
    { id: 'gallery_slide_08', image: gallery_preview_slide_08 },
    { id: 'gallery_slide_09', image: gallery_preview_slide_09 },
    { id: 'gallery_slide_10', image: gallery_preview_slide_10 },
    { id: 'gallery_slide_11', image: gallery_preview_slide_11 },
    { id: 'gallery_slide_12', image: gallery_preview_slide_12 },
    { id: 'gallery_slide_13', image: gallery_preview_slide_13 },
    { id: 'gallery_slide_14', image: gallery_preview_slide_14 },
    { id: 'gallery_slide_15', image: gallery_preview_slide_15 },
    { id: 'gallery_slide_16', image: gallery_preview_slide_16 },
    { id: 'gallery_slide_17', image: gallery_preview_slide_17 },
]

export const GALLERY_SLIDES = [
    { id: 'gallery_slide_01', slide: gallery_slide_01, categories: ['тренажёрный зал', 'ddx athletics'] },
    { id: 'gallery_slide_02', slide: gallery_slide_02, categories: ['тренажёрный зал', 'ddx athletics'] },
    { id: 'gallery_slide_03', slide: gallery_slide_03, categories: ['тренажёрный зал', 'ddx athletics'] },

    { id: 'gallery_slide_04', slide: gallery_slide_04, categories: ['тренажёрный зал', 'зона свободных весов'] },
    { id: 'gallery_slide_05', slide: gallery_slide_05, categories: ['тренажёрный зал', 'зона свободных весов'] },
    
    { id: 'gallery_slide_06', slide: gallery_slide_06, categories: ['тренажёрный зал', 'блочные тренажеры'] },
    { id: 'gallery_slide_07', slide: gallery_slide_07, categories: ['тренажёрный зал', 'блочные тренажеры'] },
    { id: 'gallery_slide_08', slide: gallery_slide_08, categories: ['тренажёрный зал', 'блочные тренажеры'] },

    { id: 'gallery_slide_09', slide: gallery_slide_09, categories: ['тренажёрный зал', 'кардио зона'] },
    { id: 'gallery_slide_10', slide: gallery_slide_10, categories: ['тренажёрный зал', 'кардио зона'] },

    { id: 'gallery_slide_11', slide: gallery_slide_11, categories: ['зал групповых занятий'] },
    { id: 'gallery_slide_12', slide: gallery_slide_12, categories: ['зал групповых занятий'] },
    { id: 'gallery_slide_13', slide: gallery_slide_13, categories: ['зал групповых занятий'] },
    
    { id: 'gallery_slide_14', slide: gallery_slide_14, categories: ['спа', 'финская сауна'] },
    { id: 'gallery_slide_15', slide: gallery_slide_15, categories: ['спа', 'финская сауна'] },

    { id: 'gallery_slide_16', slide: gallery_slide_16, categories: ['спа', 'хаммам'] },

    { id: 'gallery_slide_17', slide: gallery_slide_17, categories: ['спа', 'соляная комната'] },
]

export const CATEGORIES = {
    'тренажёрный зал': ['Все зоны', 'DDX Athletics', 'Зона свободных весов', 'Блочные тренажеры', 'Кардио зона'],
    'спа': ['Все зоны', 'Финская сауна', 'Хаммам', 'Соляная комната'],
    'галерея': ['Все зоны','тренажёрный зал', 'Зал групповых занятий', 'СПА']
}